.ics-path-override path {
  stroke-width: inherit;
}

.mantine-TextInput-input {
  border-width: 0.5px;
}


/****** SIDEBAR ******/

.mantine-AppShell-navbar 
.mantine-NavLink-root {
  padding: 0.25rem;
}

.mantine-AppShell-navbar
  .mantine-NavLink-root[data-active]
  .mantine-NavLink-section:first-child {
  background: var(--mantine-color-adiba-4);
}

.mantine-AppShell-navbar
  .mantine-NavLink-root
  .mantine-NavLink-section:first-child {
  display: flex;
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
}
